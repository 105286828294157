import { useEffect } from "react";
import SimpleBar from "simplebar";
import { slideUp, slideDown } from "@/utils/domUtils";

export const useMenuClick = () => {
  useEffect(() => {
    const menuClick = () => {
      const menuItems = document.querySelectorAll(".pc-navbar li");
      menuItems.forEach((item) => {
        item.removeEventListener("click", () => {});
      });

      const subMenus = document.querySelectorAll(
        ".pc-navbar li:not(.pc-trigger) .pc-submenu"
      );
      subMenus.forEach((submenu) => {
        submenu.style.display = "none";
      });

      const mainMenus = document.querySelectorAll(
        ".pc-navbar > li:not(.pc-caption).pc-hasmenu"
      );
      mainMenus.forEach((menu) => {
        menu.addEventListener("click", (e) => {
          // e.stopPropagation();
          let target =
            e.target.tagName === "SPAN" ? e.target.parentNode : e.target;

          if (
            target &&
            target.parentNode &&
            target.parentNode.classList.contains("pc-trigger")
          ) {
            try {
              target.parentNode.classList.remove("pc-trigger");

              // Check if target.parentNode.children[1] exists before using it
              if (target.parentNode.children[1]) {
                slideUp(target.parentNode.children[1], 200);

                setTimeout(() => {
                  // Check if target.parentNode.children[1] still exists before accessing it
                  if (target.parentNode.children[1]) {
                    target.parentNode.children[1].removeAttribute("style");
                    target.parentNode.children[1].style.display = "none";
                  }
                }, 10);
              } else {
                console.error(
                  "Error: target.parentNode.children[1] is undefined or null"
                );
              }
            } catch (error) {
              console.error("Error occurred:", error.message);
              // Optionally handle the error further if needed
            }
          } else {
            const triggers = document.querySelectorAll("li.pc-trigger");

            triggers.forEach((trigger) => {
              try {
                if (trigger) {
                  trigger.classList.remove("pc-trigger");
                  // Check if children[1] exists and then operate on it
                  if (trigger.children && trigger.children[1]) {
                    slideUp(trigger.children[1], 200);
                    setTimeout(() => {
                      // Remove inline style after slideUp animation
                      if (trigger.children[1]) {
                        trigger.children[1].removeAttribute("style");
                        trigger.children[1].style.display = "none";
                      }
                    }, 10);
                  }
                }
              } catch (error) {
                console.error("Error occurred:", error);
                // Handle the error here, e.g., log it, show a user-friendly message, etc.
              }
            });

            target.parentNode.classList.add("pc-trigger");
            target.children[1] && slideDown(target.parentNode.children[1], 200);
          }
        });
      });

      if (document.querySelector(".navbar-content")) {
        new SimpleBar(document.querySelector(".navbar-content"));
      }

      const subSubMenus = document.querySelectorAll(
        ".pc-navbar > li:not(.pc-caption) li.pc-hasmenu"
      );
      subSubMenus.forEach((menu) => {
        menu.addEventListener("click", (e) => {
          // e.stopPropagation();
          let target =
            e.target.tagName === "SPAN" ? e.target.parentNode : e.target;

          if (target.parentNode.classList.contains("pc-trigger")) {
            target.parentNode.classList.remove("pc-trigger");
            slideUp(target?.parentNode?.children[1], 200);
          } else {
            const siblings = target.parentNode.parentNode.children;
            for (let sibling of siblings) {
              sibling.classList.remove("pc-trigger");
              if (sibling.tagName === "LI") {
                slideUp(sibling?.children[1], 200);
              }
            }

            target.parentNode.classList.add("pc-trigger");
            const submenu = target.parentNode.children[1];
            if (submenu) {
              submenu.removeAttribute("style");
              slideDown(submenu, 200);
            }
          }
        });
      });
    };

    menuClick();
  }, []);
};
