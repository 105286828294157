import SimpleBar from "simplebar";
import feather from "feather-icons";
import $ from "jquery";

export const initializeAppFeatures = () => {
  if (typeof window !== "undefined") {
    window.$ = window.jQuery = $;
  }

  feather.replace();

  // Initialize SimpleBar
  const addScroller = () => {
    const elements = [
      ".navbar-content",
      ".header-notification-scroll",
      ".profile-notification-scroll",
      ".component-list-card .card-body",
    ];
    elements.forEach((selector) => {
      const element = document.querySelector(selector);
      if (element) new SimpleBar(element);
    });
  };
  addScroller();

  function rm_menu() {
    document.querySelector(".pc-sidebar") &&
      document
        .querySelector(".pc-sidebar")
        .classList.remove("mob-sidebar-active"),
      document.querySelector(".topbar") &&
        document.querySelector(".topbar").classList.remove("mob-sidebar-active"),
      document.querySelector(".pc-sidebar .pc-menu-overlay").remove(),
      document.querySelector(".topbar .pc-menu-overlay") &&
        document.querySelector(".topbar .pc-menu-overlay").remove();
  }

  // Mobile collapse event
  const mobileCollapse = document.querySelector("#mobile-collapse");
  if (mobileCollapse) {
    mobileCollapse.addEventListener("click", () => {
      const sidebar = document.querySelector(".pc-sidebar");
      if (sidebar) {
        if (sidebar.classList.contains("mob-sidebar-active")) {
          rm_menu();
        } else {
          sidebar.classList.add("mob-sidebar-active");
          sidebar.insertAdjacentHTML(
            "beforeend",
            '<div class="pc-menu-overlay"></div>'
          );
          document
            .querySelector(".pc-menu-overlay")
            .addEventListener("click", () => {
              rm_menu();
            });
        }
      }
    });
  }

  // Sidebar hide event
  const sidebarHide = document.querySelector("#sidebar-hide");
  if (sidebarHide) {
    sidebarHide.addEventListener("click", () => {
      const sidebar = document.querySelector(".pc-sidebar");
      sidebar.classList.toggle("pc-sidebar-hide");
    });
  }

  // Set active menu
  const setActiveMenu = () => {
    const elem = document.querySelectorAll(".pc-sidebar .pc-navbar a");
    elem.forEach((el) => {
      const pageUrl = window.location.href.split(/[?#]/)[0];
      if (el.href === pageUrl && el.getAttribute("href") !== "") {
        el.parentNode.classList.add("active");
        el.parentNode.parentNode.parentNode.classList.add("pc-trigger");
        el.parentNode.parentNode.parentNode.classList.add("active");
        el.parentNode.parentNode.style.display = "block";
        el.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add(
          "pc-trigger"
        );
        el.parentNode.parentNode.parentNode.parentNode.style.display = "block";
      }
    });
  };
  setActiveMenu();

  // Product likes
  const prodLikes = document.querySelectorAll(".prod-likes .form-check-input");
  prodLikes.forEach((prodLike) => {
    prodLike.addEventListener("change", (e) => {
      if (e.currentTarget.checked) {
        const likeWrapper = document.createElement("div");
        likeWrapper.classList.add("pc-like");
        likeWrapper.innerHTML = `<div class="like-wrapper"><span><span class="pc-group"><span class="pc-dots"></span><span class="pc-dots"></span><span class="pc-dots"></span><span class="pc-dots"></span></span></span></div>`;
        prodLike.parentNode.appendChild(likeWrapper);
        likeWrapper.classList.add("pc-like-animate");
        setTimeout(() => {
          likeWrapper.remove();
        }, 3000);
      } else {
        const likeElement = prodLike.parentNode.querySelector(".pc-like");
        likeElement && likeElement.remove();
      }
    });
  });

  // Auth images
  const authImages = document.querySelectorAll(".auth-main.v2 .img-brand");
  authImages.forEach((img) => {
    img.setAttribute("src", "assets/images/logo-white.svg");
  });
};
