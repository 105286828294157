import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { bedServices } from "../../../services/bedServices";
// import { HYDRATE } from "next-redux-wrapper";


const STATUSES = Object.freeze({
IDLE: "idle",
LOADING: "loading",
ERROR: "error",
});

const getPage = () => {
    return JSON.parse(localStorage.getItem("page")) || false;
  };

const initialState = {
    bedList: [],
    status: STATUSES.IDLE,
};

export const bedSlice = createSlice({
    name: 'bed',
    initialState,
    reducers: {
        // setShowAppointment: (state, action) => {
        //     state.showAppointment = action.payload;
        // },
        // setDoctorList: (state, action) => {
        //     state.doctorList = action.payload;
        // },
        // setStatus: (state, action) => {
        //     state.status = action.payload;
        // }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchBedList.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchBedList.fulfilled, (state, action) => {
            state.bedList = action.payload;
            //state.doctorLength = action.payload.length;
            state.status = STATUSES.IDLE;
        })
        .addCase(fetchBedList.rejected, (state, action) => {
            state.status = STATUSES.ERROR;
        })
        // .addCase(fetchPatientList.pending, (state, action) => {
        //     state.status = STATUSES.LOADING;
        // })
        // .addCase(fetchPatientList.fulfilled, (state, action) => {
        //     state.patientList = action.payload;
        //     state.patientLength = action.payload.length;
        //     state.status = STATUSES.IDLE;
        // })
        // .addCase(fetchPatientList.rejected, (state, action) => {
        //     state.status = STATUSES.ERROR;
        // })
        // .addCase(fetchAppointmentList.pending, (state, action) => {
        //     state.status = STATUSES.LOADING;
        // })
        // .addCase(fetchAppointmentList.fulfilled, (state, action) => {
        //     state.appointmentList = action.payload;
        //     state.appointmentLength = action.payload.length;
        //     state.status = STATUSES.IDLE;
        // })
        // .addCase(fetchAppointmentList.rejected, (state, action) => {
        //     state.status = STATUSES.ERROR;
        // })
    }
});

export const { setDoctorLength, setDoctorList, setStatus, setShowAppointment } = bedSlice.actions;
export default bedSlice.reducer;

//Thunks

export const fetchBedList = createAsyncThunk("bed/fetch", async (params) =>{
    const data = await bedServices.bedListing(params);
    console.log(data);
    //const data = await res.json();
return data;
});

// export const fetchPatientList = createAsyncThunk("patient/fetch", async () =>{
//     const data = await doctorServices.getPatientsList();
//     //const data = await res.json();
// return data;
// });

// export const fetchAppointmentList = createAsyncThunk("appointment/fetch", async () =>{
//     const data = await doctorServices.getAppointmentsList();
//     //const data = await res.json();
// return data;
// });

//Thunks

// export function fetchDoctorList() {
//   return async function fetchDoctorThunk(dispatch,getState){
//       dispatch(setStatus(STATUSES.LOADING));

//       try{
//         const data = await doctorServices.getDoctorsList();
//         //const data = await res.json();
//         dispatch(setDoctorList(data));
//         dispatch(setDoctorLength(data.length));
//         dispatch(setStatus(STATUSES.IDLE));
//       }catch(err){
//           console.log(err);
//           dispatch(setStatus(STATUSES.ERROR));
//       }
//   }
// }

