export const ENDPOINTS = {
  //authentication
  LOGIN: "api/login",
  FORGET_PASSWORD: "/forget-password",
  LOGOUT: "api/logout",
  EMAIL_VERIFY:"api/email/verify",

  //user
  USER_PROFILE: "api/user",
  UPDATE_USER: "api/update-profile",
 
  // dashboard

  GET_DOCTORS: "/api/list-doctor",
  GET_PATIENTS: "/api/list-patient",
  GET_APPOINTMENT: "/api/list-appointment",
  DOCTOR_DELETE: "/api/delete-doctor",
  PATIENT_DELETE: "/api/delete-patient",
  APPOINTMENT_DELETE: "/api/delete-appintment",
  SEND_VERIFICATION_LINK: "api/email/resend",

  //addDoctors
ADD_DOCTOR: "api/add-doctor",
UPDATE_DOCTOR: "api/update-doctor",
EDIT_DOCTOR_ID: "api/edit-doctor",

//doctor profile
GET_DOCTOR_DETAILS: "api/doctor-details",
DOCTOR_UPDATE_PASSWORD_SUBMIT: "api/update-doctor-password",
UPDATE_DOCTOR_DETAIL: "api/update-doctor-details",
GET_DOCTORS_SLOT: "api/get-doctor-slot",
UPDATE_DOCTOR_SETTINGS: "api/doctor-setting",

//patient
ADD_PATIENT: "api/add-patient",
EDIT_PATIENT: "api/edit-patient",
UPDATE_PATIENT: "api/update-patient",
PATIENT_MOBILE_SEARCH: "api/edit-Search",
PATIENT_DELETE: "api/delete-patient",

//Patient Profile
GET_PATIENT_DETAIL: "api/get-patient-detail",
UPDATE_PATIENT_PASSWORD: "api/update-patient-password",
PATIENT_DETAIL_UPDATE_SUBMIT: "api/update-patient",
PATIENT_APPOINTMENT_LIST: "api/patient/appointment/history",
PATIENT_BILL_LIST: "api/patient/bill/history",
PATIENT_PRESCRIPTION_LIST: "api/patient/prescription/history",
PATIENT_DOCUMENT_LIST: "api/patient/document/history",
CITY_LIST: "api/city",

//Role
ROLE_LISTING: "api/list-role",
ADD_ROLE: "api/add-role",
DELETE_ROLE: "api/delete-role",
GET_PERMISSIONS: "api/get-permissions",
EDIT_ROLE: "api/edit-role",
UPDATE_ROLE: "api/update-role",
FULL_ROLE_LISTING: "api/get-roles",

//speciality
SPECIALITY_LISTING: "api/list-speciality",
GET_SPECIALITY_LISTING: "api/all-speciality",
ADD_SPECIALITY: "api/add-speciality",
DELETE_SPECIALITY: "api/delete-speciality",
UPDATE_SPECIALITY: "api/update-speciality",

//schedule
SCHEDULE_LISTING: "api/list-schedule",
DELETE_SCHEDULE: "api/delete-schedule",
ADD_SCHEDULE: "api/add-schedule",
UPDATE_SCHEDULE: "api/update-schedule",
EDIT_SCHEDULE: "api/edit-schedule",

//staff
STAFF_LISTING: "api/list-staff",
DELETE_STAFF: "api/delete-staff",
ADD_STAFF: "api/add-staff",
UPDATE_STAFF: "api/update-staff",
EDIT_STAFF: "api/edit-staff",

//document
DOCUMENT_LISTING: "api/list-document",
DELETE_DOCUMENT: "api/delete-document",
ADD_DOCUMENT: "api/add-document",
UPDATE_DOCUMENT: "api/update-document",
EDIT_DOCUMENT: "api/edit-document",

//appointment
DELETE_APPOINTMENT: "api/delete-appointment",
ADD_APPOINTMENT: "api/add-appointment",
EDIT_APPOINTMENT: "api/edit-appointment",
UPDATE_APPOINTMENT: "api/update-appointment",
DOWNLOAD_APPOINTMENT: "api/download-invoice",
UPDATE_APPOINTMENT_STATUS: "api/appointment/status/update",
GET_APPOINTMENT_DETAIL: "api/appointment-details",

//bed
BED_LISTING: "api/list-bed",
DELETE_BED: "api/delete-bed",
EDIT_BED: "api/edit-bed",
UPDATE_BED: "api/update-bed",
ADD_BED: "api/add-bed",

//bill
BILL_LISTING: "api/list-bill",
DELETE_BILL: "api/delete-bill",
EDIT_BILL: "api/edit-bill",
UPDATE_BILL: "api/update-bill",
ADD_BILL: "api/add-bill",

//SERVICE
SERVICE_LISTING: "api/list-service",
DELETE_SERVICE: "api/delete-service",
EDIT_SERVICE: "api/edit-service",
UPDATE_SERVICE: "api/update-service",
ADD_SERVICE: "api/add-service",
SERVICE_LISTING_WOP: "api/all-service",

//assignBed
ASSIGN_BED_LISTING: "api/assign-listing",
ASSIGN_DELETE_BED: "api/delete-bedassign",
ASSIGN_EDIT_BED: "api/edit-bedassign",
ASSIGN_UPDATE_BED: "api/update-bedassign",
ASSIGN_ADD_BED: "api/add-bedassign",

//notice
NOTICE_LISTING: "api/list-notice",
DELETE_NOTICE: "api/delete-notice",
UPDATE_NOTICE: "api/update-notice",
ADD_NOTICE: "api/add-notice",

// prescription
CREATE_PRESCRIPTION: "api/prescription/store",
PRESCRIPTION_LIST: "api/prescription",
PRESCRIPTION_SHOW: "api/prescription/show",
PRESCRIPTION_EDIT: "api/prescription/edit",
PRESCRIPTION_UPDATE: "api/prescription/update",
PRESCRIPTION_DELETE: "api/prescription/delete",
FETCH_HISTORY: "api/prescription/investigation",
FETCH_SYMPTOMS: "api/prescription/symptoms",
FETCH_PRESCRIBED_TEST: "api/prescription/tests",
FETCH_DRUGS: "api/prescription/create",
FETCH_MEDICINE: "api/medicines",
GET_PATIENT: "api/prescription/search",
FETCH_MEDICINE_DETAILS: "api/medicines/quantity_unit",

//medicine
MEDICINE_LISTING: "api/medicines",
DELETE_MEDICINE: "api/medicines/delete",
EDIT_MEDICINE: "api/medicines/edit",
UPDATE_MEDICINE: "api/medicines/update",
ADD_MEDICINE: "api/medicines/store",
MEDICINE_LISTING_WOP: "api/all-medicine",

//calendar
PATIENT_SEARCH: "api/search-patient",
DOCTOR_SCHEDULE: "api/doctor_schedule",
GET_DOCTORS_LIST: "api/getDoctors",
TODAY_APPOINTMENT: "api/todayAppointments",
CALENDAR_APPOINTMENT: "api/calendar-appointment",
DOCTOR_APPOINTMENT: "api/doctorAppointments",
UPDATE_TODAY_APPOINTMENT: "api/todayAppointments",
CANCEL_APPOINTMENT: "api/cancelAppointment",

//layouts
LAYOUT_STORE: "api/layouts/store",

//settings
GET_UPDATE_GENERAL_SETTINGS: "api/settings",
GET_REVENUE: "api/dashboard",

};

