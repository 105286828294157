import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { doctorServices } from "../../services/doctroServices";
// import { HYDRATE } from "next-redux-wrapper";

const STATUSES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
});

const getPage = () => {
  return JSON.parse(localStorage.getItem("page")) || false;
};

const initialState = {
  noticeStatus: false,
  adminDetails: {},
  doctorLength: "",
  doctorList: [],
  doctorListLength: "",
  doctorListing: [],
  patientLength: "",
  patientList: [],
  appointmentLength: "",
  appointmentList: [],
  status: STATUSES.IDLE,
  showAppointment: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setShowAppointment: (state, action) => {
      state.showAppointment = action.payload;
    },
    setNoticeStatus: (state, action) => {
      state.noticeStatus = action.payload;
    },
    // setDoctorList: (state, action) => {
    //     state.doctorList = action.payload;
    // },
    // setStatus: (state, action) => {
    //     state.status = action.payload;
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminDetails.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAdminDetails.fulfilled, (state, action) => {
        state.adminDetails = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchAdminDetails.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchDoctorList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchDoctorList.fulfilled, (state, action) => {
        state.doctorList = action.payload;
        state.doctorLength = action.payload.length;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchDoctorList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchDoctors.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchDoctors.fulfilled, (state, action) => {
        state.doctorListing = action.payload;
        state.doctorListLength = action.payload.length;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchDoctors.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchPatientList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchPatientList.fulfilled, (state, action) => {
        state.patientList = action.payload;
        state.patientLength = action.payload.total;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchPatientList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchAppointmentList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAppointmentList.fulfilled, (state, action) => {
        state.appointmentList = action.payload;
        state.appointmentLength = action.payload.total;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchAppointmentList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const {
  setDoctorLength,
  setDoctorList,
  setStatus,
  setShowAppointment,
  setNoticeStatus,
} = userSlice.actions;
export default userSlice.reducer;

//Thunks

export const fetchAdminDetails = createAsyncThunk("admin/fetch", async () => {
  const data = await doctorServices.getAdminDetails();
  //const data = await res.json();
  return data;
});

export const fetchDoctorList = createAsyncThunk(
  "doctors/fetch",
  async (params) => {
    const data = await doctorServices.getDoctorsList(params);
    //const data = await res.json();
    return data;
  }
);

export const fetchDoctors = createAsyncThunk("doctorlist/fetch", async () => {
  const data = await doctorServices.getDoctors();
  //const data = await res.json();
  return data;
});

export const fetchPatientList = createAsyncThunk(
  "patient/fetch",
  async (params) => {
    const data = await doctorServices.getPatientsList(params);
    //const data = await res.json();
    return data;
  }
);

export const fetchAppointmentList = createAsyncThunk(
  "appointment/fetch",
  async (params) => {
    const data = await doctorServices.getAppointmentsList(params);
    //const data = await res.json();
    return data;
  }
);

//Thunks

// export function fetchDoctorList() {
//   return async function fetchDoctorThunk(dispatch,getState){
//       dispatch(setStatus(STATUSES.LOADING));

//       try{
//         const data = await doctorServices.getDoctorsList();
//         //const data = await res.json();
//         dispatch(setDoctorList(data));
//         dispatch(setDoctorLength(data.length));
//         dispatch(setStatus(STATUSES.IDLE));
//       }catch(err){
//           console.log(err);
//           dispatch(setStatus(STATUSES.ERROR));
//       }
//   }
// }
