import { api } from "@/utils/axiosConfigs";
import { defineCancelApiObject } from "@/utils/axiosUtils";
import { ENDPOINTS } from "@/utils/endpoints";

export const bedServices = {
  addBed: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.ADD_BED}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.addBed].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response;
},

bedListing: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.BED_LISTING}?length=${params.length?params.length:10}&search=${params.search?params.search:""}&field=${params.field?params.field:""}&sortOrder=${params.sortOrder?params.sortOrder:""}&page=${params.page?params.page:1}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.bedListing].handleRequestCancellation()
          .signal
        : undefined,
    });
  
    // returning the data
    return response.data;
  },

  editBed: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.EDIT_BED}/${params}`,
      method: "POST",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.editBed].handleRequestCancellation()
          .signal
        : undefined,
    });
  
    // returning the data
    return response;
  },

  deleteBed: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DELETE_BED}/${params}`,
      method: "DELETE",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.deleteBed].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response;
},

updateBed: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.UPDATE_BED}/${params.id}`,
      method: "POST",
      data: params.fData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateBed].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response;
},

assignBedListing: async function (params, cancel = false) {
  const response = await api.request({
    url: `${ENDPOINTS.ASSIGN_BED_LISTING}?length=${params.length?params.length:10}&search=${params.search?params.search:""}&field=${params.field?params.field:""}&sortOrder=${params.sortOrder?params.sortOrder:""}&assign_date=${params.assign_date?params.assign_date:""}&discharge_date=${params.discharge_date?params.discharge_date:""}&page=${params.page?params.page:1}`,
    method: "GET",
    // retrieving the signal value by using the property name
    signal: cancel
      ? cancelApiObject[this.assignBedListing].handleRequestCancellation()
        .signal
      : undefined,
  });

  // returning the data
  return response;
},

assignDeleteBed: async function (params, cancel = false) {
  const response = await api.request({
    url: `${ENDPOINTS.ASSIGN_DELETE_BED}/${params}`,
    method: "DELETE",
    // retrieving the signal value by using the property name
    signal: cancel
      ? cancelApiObject[this.assignDeleteBed].handleRequestCancellation()
        .signal
      : undefined,
  });
  // returning the data
  return response;
},

assignAddBed: async function (params, cancel = false) {
  const response = await api.request({
    url: `${ENDPOINTS.ASSIGN_ADD_BED}`,
    method: "POST",
    data: params,
    // retrieving the signal value by using the property name
    signal: cancel
      ? cancelApiObject[this.assignAddBed].handleRequestCancellation()
        .signal
      : undefined,
  });
  // returning the data
  return response;
},

assignEditBed: async function (params, cancel = false) {
  const response = await api.request({
    url: `${ENDPOINTS.ASSIGN_EDIT_BED}/${params}`,
    method: "POST",
    // retrieving the signal value by using the property name
    signal: cancel
      ? cancelApiObject[this.editBed].handleRequestCancellation()
        .signal
      : undefined,
  });

  // returning the data
  return response;
},

assignUpdateBed: async function (params, cancel = false) {
  const response = await api.request({
    url: `${ENDPOINTS.ASSIGN_UPDATE_BED}/${params.id}`,
    method: "POST",
    data: params.fData,
    // retrieving the signal value by using the property name
    signal: cancel
      ? cancelApiObject[this.updateBed].handleRequestCancellation()
        .signal
      : undefined,
  });
  // returning the data
  return response;
},



};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(bedServices);
