import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { patientServices } from "../../../services/patientServices";
// import { HYDRATE } from "next-redux-wrapper";


const STATUSES = Object.freeze({
IDLE: "idle",
LOADING: "loading",
ERROR: "error",
});

const initialState = {
    patientName: "",
    patient_id: "",
    mobileData: {},
    patientMobile: "",
    status: STATUSES.IDLE,
};

export const mobileSlice = createSlice({
    name: 'mobileSearch',
    initialState,
    reducers: {
        setPatientMobile: (state, action) => {
            state.patientMobile = action.payload;
        },
        setMobileData: (state, action) => {
            state.mobileData = action.payload;
        },
        setPatientName: (state, action) => {
            state.patientName = action.payload;
        },
        setPatient_id: (state, action) => {
            state.patient_id = action.payload;
        },
        // setDoctorList: (state, action) => {
        //     state.doctorList = action.payload;
        // },
        // setStatus: (state, action) => {
        //     state.status = action.payload;
        // }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchMobileSearch.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchMobileSearch.fulfilled, (state, action) => {
            state.mobileData = action.payload;
            state.patientName = action.payload[0]?.name;
            state.patient_id = action.payload[0]?.id;
            state.patientMobile = action.payload[0]?.mobile;
            state.status = STATUSES.IDLE;
        })
        .addCase(fetchMobileSearch.rejected, (state, action) => {
            state.status = STATUSES.ERROR;
        })
        // .addCase(fetchPatientList.pending, (state, action) => {
        //     state.status = STATUSES.LOADING;
        // })
        // .addCase(fetchPatientList.fulfilled, (state, action) => {
        //     state.patientList = action.payload;
        //     state.patientLength = action.payload.length;
        //     state.status = STATUSES.IDLE;
        // })
        // .addCase(fetchPatientList.rejected, (state, action) => {
        //     state.status = STATUSES.ERROR;
        // })
        // .addCase(fetchAppointmentList.pending, (state, action) => {
        //     state.status = STATUSES.LOADING;
        // })
        // .addCase(fetchAppointmentList.fulfilled, (state, action) => {
        //     state.appointmentList = action.payload;
        //     state.appointmentLength = action.payload.length;
        //     state.status = STATUSES.IDLE;
        // })
        // .addCase(fetchAppointmentList.rejected, (state, action) => {
        //     state.status = STATUSES.ERROR;
        // })
    }
});

export const { setPatientMobile, setMobileData, setPatientName, setPatient_id } = mobileSlice.actions;
export default mobileSlice.reducer;

//Thunks

export const fetchMobileSearch = createAsyncThunk("mobileSearch/fetch", async (dataa) =>{
    const data = await patientServices.patientMobileSearch(dataa);
    //const data = await res.json();
return data;
});

// export const fetchPatientList = createAsyncThunk("patient/fetch", async () =>{
//     const data = await doctorServices.getPatientsList();
//     //const data = await res.json();
// return data;
// });

// export const fetchAppointmentList = createAsyncThunk("appointment/fetch", async () =>{
//     const data = await doctorServices.getAppointmentsList();
//     //const data = await res.json();
// return data;
// });

//Thunks

// export function fetchDoctorList() {
//   return async function fetchDoctorThunk(dispatch,getState){
//       dispatch(setStatus(STATUSES.LOADING));

//       try{
//         const data = await doctorServices.getDoctorsList();
//         //const data = await res.json();
//         dispatch(setDoctorList(data));
//         dispatch(setDoctorLength(data.length));
//         dispatch(setStatus(STATUSES.IDLE));
//       }catch(err){
//           console.log(err);
//           dispatch(setStatus(STATUSES.ERROR));
//       }
//   }
// }

