import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import doctorsReducer from "./features/doctors/addDoctorSlice";
import patientReducer from "./features/patients/patientSlice";
import staffReducer from "./features/staff/staffSlice";
import scheduleReducer from "./features/schedule/scheduleSlice";
import mobileReducer from "./features/mobileSearch/mobileSlice";
import spacialityReducer from "./features/specialization/specialitySlice";
import bedReducer from "./features/bed/bedSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    doctors: doctorsReducer,
    patients: patientReducer,
    staff: staffReducer,
    schedule: scheduleReducer,
    mobileSearch: mobileReducer,
    speciality: spacialityReducer,
    bed: bedReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
  devTools: true,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});
