import { api } from "@/utils/axiosConfigs";
import { defineCancelApiObject } from "@/utils/axiosUtils";
import { ENDPOINTS } from "@/utils/endpoints";

export const specialityServices = {
  addSpeciality: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.ADD_SPECIALITY}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.addSpeciality].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response;
},

specialityListing: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.SPECIALITY_LISTING}?length=${params.length?params.length:10}&search=${params.search?params.search:""}&field=${params.field?params.field:""}&sortOrder=${params.sortOrder?params.sortOrder:""}&page=${params.page?params.page:1}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.specialityListing].handleRequestCancellation()
          .signal
        : undefined,
    });
  
    // returning the data
    return response;
  },

  fetchSpecialityListing: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_SPECIALITY_LISTING}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.fetchSpecialityListing].handleRequestCancellation()
          .signal
        : undefined,
    });
  
    // returning the data
    return response;
  },

  deleteSpeciality: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DELETE_SPECIALITY}/${params}`,
      method: "DELETE",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.deleteSpeciality].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response.data;
},

updateSpeciality: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.UPDATE_SPECIALITY}/${params.updateDepartId}`,
      method: "POST",
      data: params.fData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateSpeciality].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response;
},

};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(specialityServices);
