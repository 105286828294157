import { api } from "@/utils/axiosConfigs";
import { defineCancelApiObject } from "@/utils/axiosUtils";
import { ENDPOINTS } from "@/utils/endpoints";

export const scheduleServices = {
  addSchedule: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.ADD_SCHEDULE}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.addSchedule].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response;
},

scheduleListing: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.SCHEDULE_LISTING}?length=${params.length?params.length:10}&search=${params.search?params.search:""}&field=${params.field?params.field:""}&sortOrder=${params.sortOrder?params.sortOrder:""}&page=${params.page?params.page:1}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.scheduleListing].handleRequestCancellation()
          .signal
        : undefined,
    });
  
    // returning the data
    return response;
  },

  editSchedule: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.EDIT_SCHEDULE}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.editSchedule].handleRequestCancellation()
          .signal
        : undefined,
    });
  
    // returning the data
    return response;
  },

  deleteSchedule: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DELETE_SCHEDULE}/${params}`,
      method: "DELETE",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.deleteSchedule].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response.data;
},

updateSchedule: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.UPDATE_SCHEDULE}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateSchedule].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response;
},

};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(scheduleServices);
