import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { specialityServices } from "../../../services/specialityServices";
// import { HYDRATE } from "next-redux-wrapper";


const STATUSES = Object.freeze({
IDLE: "idle",
LOADING: "loading",
ERROR: "error",
});

const initialState = {
    departLength: "",
    departmentList: [],
    departmentListing: [],
    status: STATUSES.IDLE,
};

export const specialitySlice = createSlice({
    name: 'speciality',
    initialState,
    reducers: {
        // setDoctorLength: (state, action) => {
        //     state.doctorLength = action.payload;
        // },
        // setDoctorList: (state, action) => {
        //     state.doctorList = action.payload;
        // },
        // setStatus: (state, action) => {
        //     state.status = action.payload;
        // }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchSpecialityList.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchSpecialityList.fulfilled, (state, action) => {
            state.departmentList = action.payload;
            state.departLength = action.payload.data?.length;
            state.status = STATUSES.IDLE;
        })
        .addCase(fetchSpecialityList.rejected, (state, action) => {
            state.status = STATUSES.ERROR;
        })
        .addCase(fetchSpecialityListing.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchSpecialityListing.fulfilled, (state, action) => {
            state.departmentListing = action.payload;
            state.status = STATUSES.IDLE;
        })
        .addCase(fetchSpecialityListing.rejected, (state, action) => {
            state.status = STATUSES.ERROR;
        })
        // .addCase(fetchPatientList.pending, (state, action) => {
        //     state.status = STATUSES.LOADING;
        // })
        // .addCase(fetchPatientList.fulfilled, (state, action) => {
        //     state.patientList = action.payload;
        //     state.patientLength = action.payload.length;
        //     state.status = STATUSES.IDLE;
        // })
        // .addCase(fetchPatientList.rejected, (state, action) => {
        //     state.status = STATUSES.ERROR;
        // })
        // .addCase(fetchAppointmentList.pending, (state, action) => {
        //     state.status = STATUSES.LOADING;
        // })
        // .addCase(fetchAppointmentList.fulfilled, (state, action) => {
        //     state.appointmentList = action.payload;
        //     state.appointmentLength = action.payload.length;
        //     state.status = STATUSES.IDLE;
        // })
        // .addCase(fetchAppointmentList.rejected, (state, action) => {
        //     state.status = STATUSES.ERROR;
        // })
    }
});

export const { setDoctorLength, setDoctorList, setStatus } = specialitySlice.actions;
export default specialitySlice.reducer;

//Thunks

export const fetchSpecialityListing = createAsyncThunk("specialitylist/fetch", async (params) =>{
    const data = await specialityServices.specialityListing(params);
    //const data = await res.json();
return data.data;
});

export const fetchSpecialityList = createAsyncThunk("speciality/fetch", async () =>{
    const data = await specialityServices.fetchSpecialityListing();
    //const data = await res.json();
return data;
});

// export const fetchPatientList = createAsyncThunk("patient/fetch", async () =>{
//     const data = await doctorServices.getPatientsList();
//     //const data = await res.json();
// return data;
// });

// export const fetchAppointmentList = createAsyncThunk("appointment/fetch", async () =>{
//     const data = await doctorServices.getAppointmentsList();
//     //const data = await res.json();
// return data;
// });

//Thunks

// export function fetchDoctorList() {
//   return async function fetchDoctorThunk(dispatch,getState){
//       dispatch(setStatus(STATUSES.LOADING));

//       try{
//         const data = await doctorServices.getDoctorsList();
//         //const data = await res.json();
//         dispatch(setDoctorList(data));
//         dispatch(setDoctorLength(data.length));
//         dispatch(setStatus(STATUSES.IDLE));
//       }catch(err){
//           console.log(err);
//           dispatch(setStatus(STATUSES.ERROR));
//       }
//   }
// }

