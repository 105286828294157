import axios from "axios";
import { signOut } from "next-auth/react";
import { getSession } from 'next-auth/react';
import { toast } from "react-toastify";
//import { clearBrowserStorage } from "@/helper/common";

export const api = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    },
    /**
     * 
     * @param {*} status 
     * @returns 
     */
    validateStatus: function (status) {
        return status < 401 || status == 422;
    }
})

api.interceptors.request.use(async (request) => {
    const session = await getSession();
    if (session && session.user && session.user.token) {
        request.headers.Authorization = `Bearer ${session.user.token}`;
    }
    return request;
});

/**
 * defining a custom error handler for all APIs
 * @param {*} error 
 * @returns 
 */
const errorHandler = (error) => {
    const statusCode = error.response?.status;
    //logout if status code is 401
    if (statusCode == 401) {
        localStorage.clear();
        sessionStorage.clear();
        signOut({ callbackUrl: '/auth/login' });
    } else if (statusCode == 403) {
        //role error handler
        toast.error(error.response?.data?.message, {
            autoClose: 5000,
        });
        localStorage.clear();
        sessionStorage.clear();
        setTimeout(() => {
            signOut({ callbackUrl: '/auth/login' });
        }, 2000);
    }

    // logging only errors that are not 401
    if (statusCode && statusCode !== 401) {
        console.error(error)
    }

    return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})