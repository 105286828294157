import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { doctorServices } from "../../../services/doctroServices";
// import { HYDRATE } from "next-redux-wrapper";

  //for updateEmployee values
  const updateEmployeeValues = {
    roles: [],
    name: "",
    email: "",
    password: "",
    mobile: "",
    gender: "1",
    address: "",
    status: 1,
    picture: "",
  };
  

const initialState = {
    employeePicture: "", 
    updateEmployee: updateEmployeeValues,
    employeeError: {},

};

export const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        setEmployeePicture: (state, action) => {
             state.employeePicture = action.payload;
         },
         setEmployeeError: (state, action) => {
            state.employeeError = action.payload;
        },
        setUpdateEmployee: (state, action) => {
            state.updateEmployee = action.payload;
        },
        // setDoctorList: (state, action) => {
        //     state.doctorList = action.payload;
        // },
        // setStatus: (state, action) => {
        //     state.status = action.payload;
        // }
    },
});

export const { setEmployeePicture, setEmployeeError, setUpdateEmployee } = staffSlice.actions;
export default staffSlice.reducer;

// //Thunks

// export const fetchDoctorList = createAsyncThunk("doctors/fetch", async () =>{
//     const data = await doctorServices.addDoctor();
//     //const data = await res.json();
// return data;
// });

