import "@/styles/fonts/inter/inter.css";
import "@/styles/fonts/tabler-icons.min.css";
import "@/styles/fonts/feather.css";
import "@/styles/fonts/fontawesome.css";
import "@/styles/fonts/material.css";
import "@/styles/style-preset.css";
import "simplebar/dist/simplebar.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/styles/globals.css";
import "@/styles/responsive.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { Provider } from "react-redux";
import Script from "next/script";
import { ToastContainer } from "react-toastify";
import { store } from "@/redux/store";
import { useLayoutChange } from "@/hooks/useLayoutChange";
import { useMenuClick } from "@/hooks/useMenuClick";
import PageLoader from "@/components/common/page-loader";
import { SessionProvider } from "next-auth/react";
import { getSession } from "next-auth/react";
import { initializeCustomIcons } from "@/utils/initializeCustomIcons";
import { initializeAppFeatures } from "@/utils/initializeAppFeatures";
import { useRouter } from "next/router";
import "swiper/css";
import "swiper/css/pagination";

import $ from "jquery";
if (typeof window !== "undefined") {
  window.$ = window.jQuery = $;
}

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}) {
  const getLayout = Component.getLayout || ((page) => page);
  async function myFunction() {
    const sessions = await getSession();
    if (sessions?.user?.token) {
      localStorage.setItem("token", sessions?.user?.token);
      localStorage.setItem("permissions", sessions?.user?.permissions);
    }
  }
  useMenuClick();
  useLayoutChange();

  const { pathname } = useRouter();

  useEffect(() => {
    setTimeout(() => {
      const openMenu = document.querySelector(
        `li[class="pc-item pc-hasmenu active"]`
      );
      if (openMenu && openMenu?.children[1]) {
        openMenu.classList.add("pc-trigger");
        openMenu.children[1].style.display = "block"; // Or whatever style you use to show the submenu
      }
    }, "10");
  }, [pathname]);

  useEffect(() => {
    myFunction();
    initializeAppFeatures();
    initializeCustomIcons();
  }, []);

  return (
    <div translate="no" className="main-page-wrapper">
      <PageLoader />
      <SessionProvider session={session}>
        <Provider store={store}>
          {getLayout(<Component {...pageProps} />)}
          <ToastContainer
            position="bottom-right"
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Provider>
      </SessionProvider>
      <Script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz"
        crossorigin="anonymous"
      ></Script>
      <Script
        src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js"
        integrity="sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r"
        crossorigin="anonymous"
      ></Script>
    </div>
  );
}
