export const slideUp = (element, duration) => {
  if (!element) {
    console.error('Element is null or undefined in slideUp function');
    return;
  }

  element.style.transitionProperty = "height, margin, padding";
  element.style.transitionDuration = `${duration}ms`;
  element.style.boxSizing = "border-box";
  element.style.height = `${element.offsetHeight}px`;
  element.offsetHeight;
  element.style.overflow = "hidden";
  element.style.height = 0;
};

export const slideDown = (element, duration) => {
  element.style.removeProperty("display");
  let display = window.getComputedStyle(element).display;

  if (display === "none") display = "block";

  element.style.display = display;
  const height = element.offsetHeight;
  element.style.overflow = "hidden";
  element.style.height = 0;
  element.offsetHeight;
  element.style.boxSizing = "border-box";
  element.style.transitionProperty = "height, margin, padding";
  element.style.transitionDuration = `${duration}ms`;
  element.style.height = `${height}px`;
};
