import { api } from "@/utils/axiosConfigs";
import { defineCancelApiObject } from "@/utils/axiosUtils";
import { ENDPOINTS } from "@/utils/endpoints";

export const doctorServices = {
  getAdminDetails: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.USER_PROFILE}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getAdminDetails].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateDoctorSettings: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.UPDATE_DOCTOR_SETTINGS}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateDoctorSettings].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  sendVerificationLink: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.SEND_VERIFICATION_LINK}/${params}`,
      method: "GET",
      //data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.sendVerificationLink].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorsList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_DOCTORS}?length=${
        params.length ? params.length : 10
      }&search=${params.search ? params.search : ""}&field=${
        params.field ? params.field : ""
      }&sortOrder=${params.sortOrder ? params.sortOrder : ""}&daterange=${
        params.daterange ? params.daterange : ""
      }&page=${params.page ? params.page : 1}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getDoctorsList].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctors: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_DOCTORS_LIST}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getDoctors].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_DELETE}/${params}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.doctorDelete].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getPatientsList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_PATIENTS}?length=${
        params.length ? params.length : 10
      }&search=${params.search ? params.search : ""}&field=${
        params.field ? params.field : ""
      }&sortOrder=${params.sortOrder ? params.sortOrder : ""}&daterange=${
        params.daterange ? params.daterange : ""
      }&page=${params.page ? params.page : 1}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getPatientsList].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  patientDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PATIENT_DELETE}/${params}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.patientDelete].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getAppointmentsList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_APPOINTMENT}?length=${
        params.length ? params.length : 10
      }&search=${params.search ? params.search : ""}&field=${
        params.field ? params.field : ""
      }&sortOrder=${params.sortOrder ? params.sortOrder : ""}&daterange=${
        params.daterange ? params.daterange : ""
      }&page=${params.page ? params.page : 1}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getAppointmentsList].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  appointmentDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.APPOINTMENT_DELETE}/${params}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.appointmentDelete].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  addDoctor: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.ADD_DOCTOR}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.addDoctor].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateDoctor: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.UPDATE_DOCTOR}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateDoctor].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateDoctor: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.UPDATE_DOCTOR}/${params.updateId}`,
      method: "POST",
      data: params.fData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateDoctor].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  editDoctorId: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.EDIT_DOCTOR_ID}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.editDoctorId].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorDetail: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_DOCTOR_DETAILS}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getDoctorDetail].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorUpdatePasswordSubmit: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_UPDATE_PASSWORD_SUBMIT}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.doctorUpdatePasswordSubmit
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateDoctorDetail: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.UPDATE_DOCTOR_DETAIL}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateDoctorDetail].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorSlot: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_DOCTORS_SLOT}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getDoctorSlot].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorLayoutStore: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.LAYOUT_STORE}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.doctorLayoutStore].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(doctorServices);
