import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { doctorServices } from "../../../services/doctroServices";
// import { HYDRATE } from "next-redux-wrapper";

//add patient values
const addPatient = {
    name: "",
    email: "",
    mobile: "",
    address: "",
    gender: "1",
    blood_group: "",
    status: 1,
  };  

 //for update patient values
const editPatient = {
    name: "",
    email: "",
    mobile: "",
    address: "",
    gender: "1",
    blood_group: "",
    status: 1,
  };
  

const initialState = {
    paitentValues:addPatient,
    updatePatient:editPatient,

};

export const patientSlice = createSlice({
    name: 'addPatient',
    initialState,
    reducers: {
         setPatientValues: (state, action) => {
             state.paitentValues = action.payload;
         },
         setUpdatePatient: (state, action) => {
            state.updatePatient = action.payload;
        },
        // setDoctorList: (state, action) => {
        //     state.doctorList = action.payload;
        // },
        // setStatus: (state, action) => {
        //     state.status = action.payload;
        // }
    },
});

export const { setPatientValues, setUpdatePatient, setdoctorShortBiography } = patientSlice.actions;
export default patientSlice.reducer;

// //Thunks

// export const fetchDoctorList = createAsyncThunk("doctors/fetch", async () =>{
//     const data = await doctorServices.addDoctor();
//     //const data = await res.json();
// return data;
// });

